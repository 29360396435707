import Vue from 'vue';
import { ConfigurationService, registry } from '@nsftx/games-config';
import { gamesStatic, sentry, eventBus } from '@nsftx/games-sdk-js';
import webViewIntegration from '@/utility/webViewIntegration';
import {
  assign,
  has,
  upperFirst,
  lowerFirst,
} from 'lodash';
import { DataService } from '@/service';
import App from './App';
import filters from './filters'; // eslint-disable-line
import router from './router';
import store from './store';
import '@nsftx/games-sdk-js/src/utility/filters';

const qp = new URLSearchParams(window.location.search);
const layout = qp.get('layout');
const appName = qp.get('application') || 'web';
const tenantId = qp.get('tenantId');
const lang = qp.get('locale');
const sessionToken = qp.get('token');
const token = qp.get('token');
const playerId = qp.get('id') || qp.get('playerId');
const clientType = qp.get('authStrategy') || qp.get('clientType');
const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'postMessage';
const deployment = window.location.hostname.includes('nsoft.app') ? 'cf' : 'netlify';
const platform = qp.get('platform');

let deviceData;
let betshopData;
let companyData;
let accountData;
let productUuid = null;

window.addEventListener('message', (event) => {
  // Slave.Load message can occur before bus init
  if (event.data.action === 'Slave.Load') {
    productUuid = event.data.data.account.uuid;
  }
});
if (platform === 'nextGen') {
  window.addEventListener('Configuration:SetConfiguration', (event) => {
    store.dispatch('setNextGenConfig', event.detail);
  });
}

window.addEventListener('online', () => {
  store.dispatch('updateTicketValues');
  eventBus.$emit('updateSchedule');
});

window.addEventListener('visibilitychange', () => {
  store.dispatch('updateTicketValues');
  eventBus.$emit('updateSchedule');
});

window.addEventListener('online', () => {
  store.dispatch('updateTicketValues');
  eventBus.$emit('updateSchedule');
});

window.addEventListener('visibilitychange', () => {
  store.dispatch('updateTicketValues');
  eventBus.$emit('updateSchedule');
});

if (appName === 'terminal') {
  store.dispatch('user/setTerminalUser', true);
  //  store.dispatch('setIsTerminal', true);
  window.addEventListener('message', (event) => {
    if (event.data.action === 'Slave.Load') {
      const { device } = event.data.data;
      const { betshop } = event.data.data;
      const { company } = event.data.data;
      const { account } = event.data.data;
      if (event.data?.data?.settings) {
        store.dispatch('setTerminalMouseCursorEnabled', event.data.data.settings.showCursor);
      }
      if (event.data.data.betslip.blockers.length) {
        store.dispatch('disableAllBetting', true);
        store.dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: event.data.data.betslip.blockers,
          type: 'add',
        });
      }
      deviceData = device;
      betshopData = betshop;
      companyData = company;
      accountData = account;
      store.dispatch('setUserBalance', event.data.data.terminal.balance);
      store.dispatch('setTerminalUserData', event.data.data.user);
    }
    if (event.data.action === 'Betslip.Blocked') {
      store.dispatch('disableAllBetting', true);
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'add',
      });
    }
    if (event.data.action === 'Betslip.Unblocked') {
      store.dispatch('disableAllBetting', false);
      store.dispatch('setBetslipLockMessage', event.data.data.blockers[0].message);
      store.dispatch('gamesBetslip/setBetslipBlockers', {
        blockers: event.data.data.blockers,
        type: 'remove',
      });
    }
    if ((has(event.data.data, 'code') && event.data.data.code === 'error') || ((has(event.data.data, 'code')) && event.data.data.code === 'UNKNOWN') || ((has(event.data.data, 'code')) && event.data.data.code === 'T_PAYIN_VALIDATION_PRINT_STATUS_ERR')) {
      store.dispatch('betslip/lockBetslip', false);
      store.dispatch('setBetslipLockMessage', '');
      store.dispatch('setPayinProgress', false);
      store.dispatch('disable_betting', false);
      store.dispatch('gamesBetslip/setIsPayinButtonDisabled', false);
      store.dispatch('gamesBetslip/setIsPayinInProgress', false);
    }
  });
}
const getServices = (dc) => {
  if (dc === 'rs2') {
    return 'https://menhir.rs-2.nsoftcdn.com';
  }
  if (dc === 'rs') {
    return 'https://menhir.rs.nsoftcdn.com';
  }
  if (dc === 'ro') {
    return 'https://menhir.ro.nsoftcdn.com';
  }
  if (dc === 'ro2') {
    return 'https://menhir.ro-2.nsoftcdn.com';
  }
  if (process.env.NODE_ENV === 'staging') {
    return 'https://menhir.staging.gb.nsoftcdn.com';
  }
  return 'https://menhir.gb.nsoftcdn.com';
};
const getSlaveId = (id) => {
  if (id === 'TerminalHistory') {
    store.dispatch('setActiveTerminalLayout', 'VPSHistory');
    return 'PluginVPSHistory';
  }
  if (id === 'TerminalResults') {
    store.dispatch('setActiveTerminalLayout', 'VPSResults');
    return 'PluginVPSResults';
  }
  if (id === 'TerminalTicketDetails') {
    store.dispatch('setActiveTerminalLayout', 'VPSTicketDetails');
    return 'PluginVPSTicketDetails';
  }
  store.dispatch('setActiveTerminalLayout', 'VPS');
  return 'PluginVPS';
};
const checkLocale = (locale) => {
  switch (locale) {
    case 'sr':
      return 'sr-Latn';
    default:
      return locale;
  }
};
const clientAdapters = [];
const nextGenAdapterData = {
  name: 'IgniteAdapter',
  options: {
    allowedOrigins: '*',
    displayId: 'virtualpenaltyshootout',
    version: '1.1',
    providerId: 26, // NSOFT VIRTUALS PROVIDER USED FOR ALL OUR GAMES
  },
};
const gravityGatewayAdapterData = {
  name: 'GravityGatewayAdapter',
  options: {
    slaveId: getSlaveId(layout),
    eventPropagation: {
      click: true, // to propagate clicks
      keydown: '*', // to propagate keydown
    },
  },
};
if (platform === 'nextGen') {
  clientAdapters.push(nextGenAdapterData);
} else {
  clientAdapters.push(gravityGatewayAdapterData);
}

(async () => {
  const isMobile = () => window.innerWidth < 768;
  const url = window.location.search;
  const configService = new ConfigurationService({
    environment: process.env.NODE_ENV,
    requiredPaths: [],
    messageProtocol,
    applicationName: upperFirst(appName),
    channel: appName,
    translationDomains: ['web'],
    // translationsFromTC: true,
    clientAdapters,
    ui: tenantId,
    deployment,
  });
  const config = await configService.getConfiguration();
  config.locale = checkLocale(config.locale);
  if (appName === 'terminal') {
    config.applicationName = 'Terminal';
  }
  const data = {
    company: tenantId,
    channel: config.productId,
    lang: lang || 'en',
    mob: isMobile(url),
  };
  const product = registry.getProduct(config.productName);
  const translations = await gamesStatic.getTranslations(config.locale);
  const [alias] = product.aliases;
  store.state.config.productAlias = alias;
  store.state.servicesApi = getServices(config.platformDataCenter);
  store.state.config.productUuid = productUuid;
  if (appName === 'terminal') {
    store.dispatch('setTerminal', true);
    store.state.config.device = deviceData;
    store.state.config.betshop = betshopData;
    store.state.config.companyData = companyData;
    store.state.config.account = accountData;
  }
  store.state.config.appLayout = layout || config.ui.config.layout || 'Compact';
  store.state.config.productNameLabel = 'productName';
  if (sessionToken) {
    store.state.config.token = sessionToken;
  }

  const isPaymentPerBetStrategy = config.paymentStrategy[lowerFirst(appName)].value === 'perBet';
  const betslipStaticConfig = config.ui?.config?.betslip;
  const gamesBetslipConfig = {
    channel: appName,
    rules: config.rules,
    taxes: config.taxes,
    isFuturePerTicketAllowed: false,
    betLayout: betslipStaticConfig?.betLayout ?? 'A',
    isPossibleWinActive: betslipStaticConfig?.isPossibleWinActive ?? true,
    isPaymentBetAllowed: betslipStaticConfig?.isPaymentBetAllowed ?? true,
    isPaymentPerBetAllowed: betslipStaticConfig?.isPaymentPerBetAllowed ?? true,
    isFuturePerBetAllowed: false,
    isTicketCancelAllowed: betslipStaticConfig?.isTicketCancelAllowed ?? true,
    isTicketRebetAllowed: false,
    isPaymentPerBetStrategy,
    isAddButtonActive: false,
    minIncrement: betslipStaticConfig?.minIncrement ?? 0.01,
    ticketTypes: betslipStaticConfig?.ticketTypes ?? ['single'],
  };

  store.dispatch('gamesBetslip/setConfig', gamesBetslipConfig);
  store.dispatch('setRules', config.rules);

  const configData = { ...data, ...config };
  store.dispatch('set_config', configData);
  store.dispatch('set_product_config', DataService.parseConfig(config));
  if (platform === 'nextGen') await store.dispatch('updateConfigWithNextGenConfig');
  store.dispatch('set_translations', assign(config.translations, translations));
  store.dispatch('setDeviceLayout');

  if (token && config.ui.config.mode === 'standalone') {
    store.state.config.platformConfig.clientType = clientType;
    store.state.config.platformConfig.playerId = playerId;
    await store.dispatch('user/setUserSessionToken', token);
    await store.dispatch('getPlayer', { config, token });
  }

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      sampleRate: 0.01,
      trackComponents: true,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network Error',
        'timeout of 5000ms exceeded',
        'timeout of 10000ms exceeded',
        'Android is not defined',
        /.*NotAllowedError.*/gm,
        /.*WakeLock.*/gm,
      ],
    });
  }

  const vm = new Vue({
    router,
    store,
    beforeCreate() {
      if (webViewIntegration.isActive()) {
        // webViewIntegration.init();
        if (window.WebAppListener) {
          window.WebAppListener.sendMessage('Slave.Init');
        }
      }
    },
    render: (h) => h(App),
  });

  vm.$mount('#app');
})();
