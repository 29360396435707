import axios from 'axios';
import { toLower } from 'lodash';

const httpAjs = axios.create({
  timeout: 10000,
});

export default {
  async getJackpot(config) {
    const channelName = toLower(config.applicationName);
    const channelNameMapper = {
      mobile: 'mob',
      web: 'web',
      terminal: 'terminal',
    };
    const channelIds = {
      mobile: 4,
      terminal: 2,
      web: 3,
    };
    const channelId = channelIds[toLower(config.applicationName)];
    let baseURL = process.env.VUE_APP_GAMES_AJS_API;

    if (config.environment === 'production' && config.platformDataCenter) {
      const suffix = config.platformDataCenter.toUpperCase();
      baseURL = process.env[`VUE_APP_GAMES_AJS_API_${suffix}`] || baseURL;
    }

    const response = await httpAjs.get('/open/nonstandalone', {
      baseURL,
      data: {
        isJackpotRequest: true,
      },
      params: {
        account: config.tenantId,
        game: config.productId,
        channel: channelId,
        betshop: channelNameMapper[channelName],
        location: channelNameMapper[channelName],
      },
    });
    return response.data;
  },
};
